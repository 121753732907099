import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Loader, Header } from "src/components";
import { Form, Formik } from "formik";
import moment from "moment";
import { TextInputOne, ButtonOne } from "src/components/form-inputs";
import { withRouter } from "react-router-dom";
import GenericLinksTable from "./generic-links-table";
import { Container } from "../../../../components";
import CreateGenericLink from "./create-generic-link";
import GeneratedLink from "./generated-link";
import styles from "./_generic-links.module.scss";

class GenericLinks extends Component {
  state = {
    isLoading: false,
    pageNumber: 1,
    showCreateModal: false,
    showGeneratedLinkModal: false,
    search: "",
  };

  componentDidMount() {
    this.requestGenericLinkSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_GENERIC_LINKS,
      () => this.setState({ isLoading: true })
    );
    this.genericLinkSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_GENERIC_LINKS_SUCCESS,
      this.handleFetchSuccess
    );
    this.genericLinkSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_GENERIC_LINKS_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.props.requestAdminGenericLinksList({
      page: this.state.pageNumber,
    });
  }

  componentWillUnmount() {
    this.requestGenericLinkSubscription();
    this.genericLinkSubscriptionSuccess();
    this.genericLinkSubscriptionFailure();
  }

  handleFetchSuccess = () => {
    this.setState({ isLoading: false });
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminGenericLinksList({
          page: pageNumber,
        })
    );
  };

  openCreateEditModal = () => {
    this.setState({ showCreateModal: true });
  };

  closeCreateEditModal = () => {
    this.setState({ showCreateModal: false });
  };

  handleOnCompleteCreateGenericLink = () => {
    this.setState({ showCreateModal: false, showGeneratedLinkModal: true });
  };

  handleOnCompleteGeneratedLink = () => {
    this.setState({ showGeneratedLinkModal: false }, () => {
      this.props.requestAdminCreateGenericLinkClear();
      this.props.requestAdminGenericLinksList({
        page: this.state.pageNumber,
      });
    });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value.toLowerCase() });
  };

  render() {
    const { isLoading, showCreateModal, showGeneratedLinkModal, search } =
      this.state;
    const { genericLinks } = this.props;

    let regex = new RegExp(search, "i");
    const filteredData = (genericLinks?.checkouts || []).filter((item) =>
      regex.test((item?.fundName && item?.fundName.toLowerCase()) || "")
    );

    return (
      <div className={styles["one-time-transactions"]}>
        <Header title="Generic Links" />
        <Container>
          <div className={styles["search-box"]}>
            <Formik initialValues={{ search: "" }}>
              <Form>
                <div className={styles["search-input-box"]}>
                  <TextInputOne
                    name="search"
                    id="search"
                    type="text"
                    value={search}
                    onChange={this.handleSearch}
                    inputType={"onlysomecharactersallowed"}
                    placeholder="Search by entering ID or transaction Fund Name....."
                  />
                  <div className={styles["search-icon"]}>
                    <img src="/img/search.svg" alt="search" />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <div className={styles["table-data"]}>
            <GenericLinksTable
              data={filteredData || []}
              handlePaginationChange={this._handlePaginationChange}
              totalItems={genericLinks?.checkouts || 0}
            />
          </div>

          <div className={styles["divider"]} />
          <div className={styles["footer"]}>
            <ButtonOne onClick={this.openCreateEditModal}>
              <img
                src="/img/plusnew.svg"
                alt="plus"
                className={styles["plus-btn"]}
              />
              <div>Create New Generic Link</div>
            </ButtonOne>
          </div>
        </Container>
        {showCreateModal && (
          <CreateGenericLink
            show={showCreateModal}
            handleOnCompleteCreateGenericLink={
              this.handleOnCompleteCreateGenericLink
            }
            handleClose={this.closeCreateEditModal}
          />
        )}
        <GeneratedLink
          show={showGeneratedLinkModal}
          handleOnCompleteGeneratedLink={this.handleOnCompleteGeneratedLink}
        />
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default withRouter(GenericLinks);
