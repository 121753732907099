export const paymentMethods = [
  {
    name: "UPI",
    value: "upi",
  },
  {
    name: "Net Banking",
    value: "internet_banking",
  },
  {
    name: "Bank Transfer",
    value: "bank_transfer",
  },
  {
    name: "Existing Mandate",
    value: "mandate"
  }
];

export const mandateAuthenticationMethods = [
  {
    name: "UPI",
    value: "upi",
  },
  {
    name: "Net Banking",
    value: "net_banking",
  },
  {
    name: "Debit card",
    value: "bank_transfer",
  },
]

export const bankCodes = [
  'DLXB','IDIB','KARB','IBKL','INDB','CSBK','USFB','YESB','CNRB','AIRP','MAHB','CITI','RATN',
  'PYTM','PUNB','ICIC','SIBL','KKBK','SBIN','DBSS','DEUT','SCBL','TMBL','JSFB','AUBL','ESFB',
  'IDFB','HDFC','DCBL','UTIB','BARB','CBIN','FDRL','CIUB','HSBC','APGB','COSB','VARA','BDBL',
  'IOBA','KVBL','KVGB','JAKA','STCB','UBIN','BKID','CLBL','SHIX','JFSB','JIOP','ZCBL','SURY',
  'UCBA','TNSC','SRCB','PSIB'
]
